<script lang="ts">
import { Options, Vue } from 'vue-class-component';

@Options({})
export default class Logo extends Vue {}
</script>

<template>
    <svg x="0px" y="0px" viewBox="800 400 1600 1400">
        <g>
            <g>
                <path
                    class="st0"
                    d="M1247.89,866.89c-1.22-1.7-2.4-3.42-3.62-5.12c-4.49-6.33,4.77,7.87,0.81,1.19c-2.14-3.6-4.25-7.2-6.19-10.91
			c-1.94-3.71-3.75-7.48-5.43-11.31c-0.7-1.59-1.38-3.2-2.04-4.82c-3.02-7.41,1.34,4.08,0.22,0.75c-2.55-7.62-4.9-15.26-6.62-23.12
			c-0.89-4.09-1.66-8.21-2.28-12.34c-0.3-2-0.67-9.12-0.11,0.18c-0.12-2.08-0.41-4.16-0.56-6.24c-0.58-7.98-0.64-15.98-0.17-23.96
			c0.22-3.81,0.57-7.61,1.03-11.4c0.13-1.04,0.03-5.36,0.88-6.17c-0.83,4.5-0.94,5.1-0.31,1.78c0.14-0.68,0.28-1.36,0.42-2.04
			c3.37-16.07,9.12-31.5,17.07-45.85c-1.29,2.3-2.58,4.61-3.87,6.92c9.45-16.56,21.61-31.18,35.97-43.69l-6.03,5.14
			c7.98-6.78,16.47-12.91,25.53-18.16c4.23-2.45,8.57-4.71,13-6.78c2.36-1.1,4.78-2.08,7.16-3.13c3.12-1.39-3.08,1.16-2.96,1.13
			c1.83-0.47,3.71-1.35,5.52-1.95c9.9-3.3,20.02-5.38,30.35-6.77c5.68-0.77-7.74,0.4,0.24-0.04c2.27-0.12,4.55-0.19,6.83-0.21
			c5.43-0.03,10.86,0.26,16.27,0.88c1.24,0.14,2.48,0.33,3.73,0.48c4.35,0.53-7.77-1.47-3.47-0.57c2.14,0.45,4.3,0.85,6.43,1.35
			c5.18,1.22,10.3,2.74,15.31,4.53c8.05,2.88-6.9-3.06-1.22-0.57c2.31,1.01,4.6,2.02,6.86,3.11c4.52,2.19,8.93,4.61,13.21,7.23
			c1.06,0.65,2.1,1.39,3.18,2.01c-10.05-5.74-6.37-4.32-4.02-2.63c2.04,1.47,4.07,2.94,6.06,4.48c3.97,3.09,7.65,6.45,11.4,9.81
			c4.64,4.16-3.44-3.51-3.36-3.46c0.84,0.52,1.99,2.08,2.58,2.72c1.71,1.83,3.36,3.71,4.96,5.62c2.82,3.37,7.51,7.79,9.01,11.94
			c-0.02-0.05-5.32-8.67-2.3-3.52c0.79,1.34,1.61,2.66,2.38,4.01c1.39,2.43,2.71,4.89,3.95,7.4c1.1,2.23,2.13,4.49,3.11,6.78
			c0.47,1.09,0.85,2.39,1.41,3.45c-4.47-8.59-1.65-4.53-0.68-1.55c1.65,5.05,2.97,10.2,3.95,15.42c0.33,1.74,2.04,4.84,0.32,0.98
			c-0.26-2.8-0.28-2.77-0.07,0.1c0.01,0.73,0.02,1.44,0.03,2.17c0.71,6.61-0.02-2.24,0.67-1.57c0.62,0.59-2.09,5.42-2.33,5.89
			c1.29-2.31,2.59-4.61,3.88-6.92c-1.14,1.77-2.38,3.23-3.94,4.64c2.01-1.72,4.02-3.43,6.03-5.15c-0.92,0.71-2.35,1.27-3.1,2.07
			c-1.41,0.72-0.85,0.55,1.7-0.53c2.85-1.01,3.15-1.17,0.89-0.47c-0.89,0.11-2.2,0.25-3.03,0.57c6.41-2.42,6.63-0.12,3-0.6
			c-3.64-0.48,9.45,2.28,3.51,0.55c-4.92-1.44,4.63,1.76,4.56,2.02c-0.74-0.33-1.45-0.7-2.15-1.1c-1.74-1-0.97-0.47,2.34,1.6
			c3.4,2.4,4,2.72,1.81,0.98c1.62,1.62,3.24,3.24,4.85,4.86c-0.71,0.03-5.23-7.29-0.82-0.69c3.47,5.18,1.02,1.03,0.29-0.22
			c-2.03-3.53,2.08,7.92,0.77,2.05c-0.37-1.65-0.87-3.26-1.2-4.91c-0.51-2.53-0.02-6.55-0.09,1.41c0.01-1.35-0.22-2.74-0.29-4.09
			c-0.17-3.36-0.12-6.73,0.03-10.08c0.26-5.76,1.31-11.38,1.78-17.1c-0.76,9.18,0.2-0.5,0.83-3.13c0.66-2.73,1.4-5.43,2.21-8.11
			c3.51-11.63,8.44-22.73,14.35-33.33c-1.29,2.31-2.58,4.61-3.88,6.92c13.09-22.89,30.73-42.61,50.71-59.71
			c-2.01,1.71-4.02,3.43-6.03,5.14c15.74-13.28,32.76-24.94,50.69-35.08c8.67-4.89,17.52-9.44,26.55-13.64
			c4.46-2.08,8.96-4.06,13.49-5.96c2.1-0.88,4.22-1.73,6.32-2.59c4.51-1.83-0.42,0.12-1.12,0.42c1.88-0.8,3.82-1.46,5.74-2.17
			c47.36-17.58,96.54-28.16,146.72-33.4c2.82-0.29,3.62-1.75-1.17,0.07c0.96-0.37,2.63-0.21,3.67-0.28
			c2.86-0.21,5.71-0.41,8.56-0.58c6.54-0.39,13.07-0.66,19.62-0.83c12.67-0.32,25.36-0.22,38.02,0.3
			c12.47,0.51,24.94,1.41,37.35,2.78c5.66,0.62,11.28,1.44,16.91,2.21c3.88,0.52-3.53-0.6-3.41-0.58c1.45,0.35,3.01,0.52,4.48,0.8
			c3.35,0.63,6.69,1.31,10.02,2.05c5.53,1.24,11.02,2.65,16.44,4.27c2.52,0.76,5.02,1.56,7.52,2.41c1.86,0.64,10.75,4,4.93,1.72
			c-6.26-2.47,3.53,1.65,4.71,2.21c2.36,1.12,4.71,2.29,7.01,3.53c2.65,1.42,5.21,2.96,7.79,4.48c1.15,0.67,4.63,3.02-0.56-0.46
			c-5.59-3.74-0.59-0.36,0.65,0.55c3.86,2.8,7.54,5.83,11.06,9.05c0.7,0.64,1.39,1.3,2.09,1.95c4.51,4.15-3.26-3.28-3.32-3.39
			c1.35,2.23,4.04,4.36,5.73,6.36c3.09,3.64,5.91,7.45,8.67,11.34c3.61,5.08-5.14-8.37-0.7-1.01c1.48,2.45,2.88,4.94,4.21,7.48
			c2.52,4.8,4.63,9.76,6.73,14.75c1.45,3.46-1.86-4.4-1.54-4.1c0.69,0.63,1.2,3.52,1.52,4.53c0.87,2.73,1.66,5.48,2.38,8.25
			c1.34,5.24,2.39,10.55,3.14,15.9c0.18,1.3,0.77,5.69,0.1,0.49c-0.74-5.75-0.03,0.63,0.03,1.46c0.17,2.53,0.27,5.07,0.29,7.61
			c0.06,5.71-0.22,11.42-0.88,17.09c-0.11,0.95-0.23,1.89-0.36,2.84c-0.72,5.25-0.82,0.48,0.33-1.35c-1.21,1.91-1.31,6.14-1.89,8.38
			c-2.82,10.78-7.11,21.06-12.49,30.82c1.29-2.31,2.59-4.61,3.87-6.92c-8.53,15-19.56,28.2-32.66,39.43
			c2.01-1.71,4.02-3.43,6.03-5.14c-10.75,8.99-22.6,16.56-35.43,22.23c-1.1,0.48-6.42,2.24-1.53,0.73c4.94-1.52-0.51,0.16-1.6,0.53
			c-3.59,1.24-7.24,2.34-10.93,3.28c-3.08,0.78-6.18,1.46-9.3,2.01c-2.82,0.51-15.29,1.81-4.74,0.96
			c14.1,17.18,28.2,34.35,42.3,51.53c1.59-5.35,3.68-10.43,6.3-15.35c-1.29,2.31-2.58,4.61-3.87,6.92
			c5.46-9.67,12.66-17.89,21.07-25.1l-6.03,5.14c8.83-7.32,18.54-13.39,28.8-18.49c5.23-2.61,10.65-4.7,15.97-7.1
			c-8.22,3.7-0.68,0.32,1.55-0.47c3.3-1.16,6.62-2.26,9.97-3.3c12.03-3.74,24.28-6.84,36.69-9.1c5.91-1.08,11.86-1.97,17.84-2.63
			c-0.21,0.02,4.12-0.26,4.21-0.43c-0.6,1.18-7.55,0.52-1.14,0.14c3.49-0.21,6.99-0.34,10.48-0.36c5.88-0.05,11.76,0.16,17.62,0.68
			c3.07,0.27,6.11,0.69,9.16,1.09c4.8,0.62-8.98-1.89-2.01-0.27c1.98,0.46,3.97,0.83,5.95,1.31c2.61,0.64,5.21,1.36,7.78,2.16
			c1.28,0.4,2.56,0.81,3.82,1.26c1.15,0.4,7.55,2.88,1.1,0.3c-6.61-2.64,3.32,1.66,4.63,2.33c2.55,1.29,6.91,2.9,8.82,5.01
			c-0.08-0.09-8.78-6.22-3.6-2.37c1.1,0.82,2.23,1.62,3.32,2.46c2.28,1.77,4.49,3.64,6.61,5.6c2.71,2.5,3.79,5.78-2.59-2.69
			c0.75,0.99,1.78,1.92,2.58,2.85c2.11,2.41,4.1,4.92,5.98,7.51c0.75,1.04,1.44,2.12,2.2,3.15c-6.33-8.59-3.51-5.61-2.26-3.34
			c1.4,2.54,2.85,5.01,4.12,7.61c1.26,2.56,2.16,5.42,3.53,7.91c-0.2-0.36-2.94-8.56-1.2-3.15c0.59,1.83,1.2,3.66,1.72,5.52
			c0.88,3.09,1.62,6.22,2.23,9.37c0.25,1.26,0.41,2.54,0.66,3.79c1.31,6.5-0.46-6.99-0.03-0.83c0.45,6.41,0.53,12.78-0.09,19.19
			c-0.16,1.59-0.42,3.17-0.58,4.76c-0.4,3.8,1.58-6.71,0.22-1.3c-0.86,3.42-1.66,6.82-2.78,10.17c-2.09,6.31-4.86,12.32-8.11,18.11
			c1.29-2.31,2.58-4.61,3.88-6.92c-6.42,10.99-14.54,20.64-24.17,28.95c2.01-1.72,4.02-3.43,6.03-5.14
			c-8.36,6.96-17.41,12.44-27.25,17.02c-3.78,1.76,0.94-0.43,2.24-0.78c-1.2,0.32-2.38,0.87-3.56,1.26
			c-2.39,0.8-4.81,1.51-7.26,2.13c-5.53,1.4-11.13,2.28-16.81,2.77c15.64,19.25,31.29,38.49,46.93,57.74
			c2.88-3.32,5.95-6.34,9.29-9.2c-2.01,1.72-4.02,3.43-6.03,5.15c4.33-3.53,8.92-6.67,13.85-9.29c2.48-1.32,5.02-2.42,7.58-3.57
			c3.9-1.75-7.59,2.36-1.37,0.62c1.5-0.42,2.97-0.95,4.48-1.36c2.71-0.76,5.47-1.4,8.24-1.92c1.63-0.3,10.9-1.09,5.1-0.92
			c-5.93,0.17,3.67-0.07,5.2-0.07c3.16,0.01,6.32,0.17,9.47,0.47c1.26,0.12,2.51,0.3,3.77,0.43c6.8,0.71-7.04-1.48-2.49-0.38
			c2.77,0.67,5.53,1.24,8.26,2.04c2.41,0.71,4.73,1.77,7.15,2.41c-9.59-2.56-5.69-2.32-2.72-0.96c1.79,0.82,3.56,1.69,5.31,2.61
			c2.62,1.37,5.14,2.9,7.68,4.42c2.48,1.48,5.03,4.42-3.79-2.52c1.03,0.8,2.14,1.52,3.19,2.31c2.36,1.78,4.66,3.64,6.89,5.58
			c1.25,1.08,2.43,2.21,3.66,3.3c5.22,4.63-6.76-7.36-1.76-1.83c3.79,4.19,7.48,8.4,10.85,12.93c1.87,2.52,7.15,11.44,0.88,0.81
			c1.69,2.87,3.48,5.66,5.09,8.59c2.88,5.24,5.47,10.65,7.74,16.18c0.49,1.2,0.91,2.51,1.46,3.69c-4.28-9.22-2-5.52-1.15-2.85
			c0.87,2.75,1.76,5.49,2.52,8.28c1.36,4.98,2.48,10.02,3.34,15.12c0.43,2.53,0.63,5.13,1.11,7.64c-0.05-0.28-0.75-8.55-0.42-3.35
			c0.11,1.71,0.27,3.42,0.37,5.13c0.58,10.29,0.2,20.63-1.06,30.85c-0.18,1.42-0.37,2.85-0.58,4.26c-0.17,1.14-1.13,7.56-0.28,1.99
			c0.84-5.55-0.09,0.18-0.29,1.12c-0.29,1.41-0.61,2.81-0.94,4.22c-1.38,5.9-3.05,11.72-4.99,17.45
			c-3.89,11.52-8.9,22.6-14.81,33.22c1.29-2.31,2.58-4.62,3.88-6.92c-10.85,19.1-24.66,36.26-41.26,50.67
			c2.01-1.71,4.02-3.43,6.03-5.14c-8.94,7.61-18.54,14.38-28.84,20.03c-5.05,2.77-10.33,4.91-15.49,7.42
			c-3.26,1.58,7.69-2.73,1.88-0.72c-1.36,0.48-2.71,0.97-4.08,1.43c-3.01,1.02-6.07,1.92-9.14,2.75
			c15.64,19.25,31.28,38.49,46.92,57.75c0.78-0.86,1.61-1.65,2.5-2.38c-2.01,1.72-4.02,3.43-6.03,5.15
			c1.69-1.28,4.22-2.12,5.73-3.47c-4.94,1.8-5.85,2.26-2.72,1.39c3.35-0.57,2.2-0.55-3.44,0.04c0.84,0.19,4.35,0.32,5.19,0.16
			c-6.12-1.15-7.4-1.33-3.84-0.55c1.15,0.29,2.28,0.63,3.4,1.02c-6.28-2.66-7.67-3.18-4.18-1.53c3.56,2,2.18,0.98-4.13-3.03
			c0.48,0.35,0.95,0.7,1.43,1.05c3.11,2.64,1.96,1.35-3.46-3.86c0.96,1.02,1.87,2.08,2.73,3.17c2.34,3.2,1.73,2.17-1.81-3.1
			c0.5,1.74,1.99,3.86,2.91,5.49c1.47,3.43,1.19,2.51-0.85-2.78c0.47,1.32,0.9,2.65,1.31,4c0.8,2.71,1.47,5.47,2.03,8.24
			c0.43,2.09,0.78,4.19,1.1,6.31c0.21,1.4,0.4,2.81,0.57,4.22c-0.55-4.95-0.64-5.68-0.29-2.16c0.74,11.87,1.38,23.63,1.03,35.54
			c-0.35,11.85-1.4,23.68-3.09,35.42c-0.21,1.43-1.64,12.15-0.59,4.18c-0.32,2.42-0.93,4.84-1.42,7.22
			c-1.25,6.01-2.68,11.98-4.27,17.91c-5.83,21.68-13.76,42.93-24.54,62.65c1.29-2.31,2.58-4.61,3.87-6.92
			c-8.7,15.51-19.28,29.75-32.69,41.52c2.01-1.71,4.02-3.43,6.03-5.14c-6.38,5.4-13.17,10.2-20.63,14
			c-1.85,0.94-3.74,1.75-5.61,2.64c-6.4,3.02,6.56-2.03-0.2,0.05c-3.95,1.21-7.87,2.39-11.93,3.19c-2.02,0.4-4.03,0.68-6.07,1.01
			c-7.07,1.13,7.51-0.1,0.38-0.02c-4.07,0.05-8.06-0.1-12.11-0.43c-7.18-0.58,8.07,1.96,1.11,0.17c-1.95-0.5-3.88-0.95-5.81-1.55
			c-3.71-1.16-6.41-3.97,1.59,0.81c-1.68-1-3.69-1.82-5.42-2.73c-6.34-3.31,8.03,6.27,2.74,1.94c-1.31-1.07-2.62-2.14-3.92-3.2
			c-4.99-4.08,6.89,7.95,2.6,2.62c-1.06-1.31-2.13-2.64-3.21-3.94c-3.96-4.81,4.73,8.41,1.58,2.57c-0.81-1.5-1.67-2.95-2.42-4.48
			c-0.63-1.28-2.04-3.28-2.07-4.65c0.67,1.8,1.35,3.59,2.03,5.4c-0.36-1.07-0.7-2.15-1.01-3.23c-0.46-1.62-0.85-3.26-1.18-4.91
			c-0.62-3.13,0.55-6.99,0,1.85c0.19-3-0.3-6.06-0.09-9.09c0.09-1.2,0.94-7.38,0.03-1.72c-0.89,5.58,0.25-0.37,0.62-1.59
			c1.04-3.41,2.47-6.6,4.19-9.71c-1.29,2.31-2.58,4.61-3.87,6.92c1.45-2.4,3.03-4.61,4.82-6.76c-21.59-8.79-43.2-17.58-64.79-26.37
			c0.51,3.03,0.92,6.06,1.38,9.09c0.61,4.07-0.66-7.3-0.16-1.37c0.11,1.36,0.25,2.71,0.35,4.07c0.49,6.44,0.7,12.91,0.62,19.37
			c-0.07,6.12-0.4,12.25-0.97,18.34c-0.28,3.05-0.63,6.09-1.03,9.13c-0.23,1.69-0.48,3.38-0.74,5.06c-0.33,2.13-1.98,6.42,0.19-0.88
			c-3.51,11.77-5.46,23.82-9.62,35.48c-4.36,12.2-9.76,23.99-16.01,35.33c1.29-2.31,2.58-4.61,3.88-6.92
			c-12.77,22.67-29.06,43.01-48.7,60.1c2.02-1.72,4.02-3.43,6.03-5.14c-9.81,8.35-20.28,15.87-31.58,22.08
			c-2.89,1.59-5.83,3.1-8.82,4.5c-2.43,1.14-5.88,1.94-8.03,3.5c0.71-0.51,6.63-2.27,1.36-0.62c-1.39,0.44-2.75,0.94-4.14,1.37
			c-3.46,1.08-6.96,2.02-10.5,2.8c-2.82,0.63-5.68,1.15-8.54,1.57c0.23-0.04-4.18,0.73-4.31,0.55c0.16,0.23,7.04-0.34,1.99-0.19
			c-6.09,0.18-12.01-0.15-18.06-0.66c-5.6-0.47,2.9,0.5,3.35,0.62c-1.2-0.33-2.52-0.48-3.75-0.74c-2.8-0.61-5.57-1.33-8.32-2.15
			c-2.44-0.74-4.83-1.6-7.24-2.44c-4.64-1.62,9.28,4.19,2.9,1.12c-1.44-0.7-2.9-1.33-4.34-2.03c-2.3-1.13-4.56-2.33-6.78-3.6
			c-3.43-1.95-10.38-7.97-0.27,0.17c-4.53-3.65-9.29-6.87-13.62-10.79c-2.92-2.65-7.31-9.05,0.43,0.65c-1.6-2.01-3.53-3.8-5.19-5.77
			c-1.68-1.97-3.3-3.99-4.85-6.06c-0.78-1.03-1.55-2.08-2.29-3.13c-5.02-7.08,1.52,1.03,1.04,1.76c0.4-0.61-7.19-13.13-7.8-14.48
			c-0.5-1.09-3.77-9.6-1.84-4.02c1.77,5.1,0.17,0.33-0.26-0.96c-0.7-2.11-1.34-4.23-1.94-6.37c-1.69-6.07-2.78-12.21-3.85-18.41
			c-0.68-4.02-1.38-0.45,0.17,1.88c-0.4-0.61-0.34-3.99-0.37-4.42c-0.26-3.69-0.38-7.39-0.38-11.08c0.01-7.06,0.48-14.11,1.36-21.1
			c0.19-1.48,0.45-2.94,0.63-4.42c0.45-3.84-1.27,5.96-0.05,0.22c0.77-3.65,1.57-7.29,2.53-10.9c3.84-14.45,9.48-28.31,16.59-41.46
			c-23.21-9.31-46.41-18.62-69.62-27.93c0.11,10.99-0.45,21.95-1.79,32.86c-0.34,2.73-0.78,5.43-1.14,8.15
			c-0.55,3.32-0.43,2.71,0.37-1.84c-0.25,1.35-0.5,2.7-0.78,4.04c-1.01,5.04-2.17,10.06-3.48,15.03
			c-5.53,20.94-13.76,41.05-24.22,60.01c1.29-2.31,2.58-4.62,3.87-6.92c-14.84,26.41-33.97,50.06-56.98,69.79
			c2.01-1.72,4.03-3.43,6.04-5.15c-13.43,11.33-27.97,21.23-43.52,29.43c-6.93,3.65-14.05,6.79-21.22,9.94
			c-6.8,2.98,6.56-2.32-0.43,0.2c-2.01,0.72-4,1.47-6.01,2.17c-4.7,1.62-9.45,3.11-14.24,4.46c-8.21,2.32-16.53,4.23-24.93,5.72
			c-4.2,0.75-8.41,1.4-12.65,1.94c-2.12,0.27-4.24,0.48-6.35,0.74c-6.45,0.79,7.02-0.37,0,0.03c-17.78,1.04-35.5,0.7-53.16-1.75
			c-3.19-0.44-7.83-2.87,0.62,0.17c-1.89-0.68-4.31-0.78-6.28-1.18c-3.48-0.7-6.95-1.47-10.4-2.32c-8.95-2.21-17.65-5.07-26.35-8.14
			c-7.37-2.6,8.48,3.7,1.31,0.56c-1.93-0.85-3.86-1.66-5.77-2.53c-3.82-1.74-7.6-3.61-11.3-5.59c-3.71-1.98-7.28-4.16-10.87-6.32
			c-6.58-3.96,6.9,4.87,2.6,1.75c-1.96-1.43-3.96-2.81-5.89-4.29c-3.29-2.53-6.49-5.18-9.55-7.98c-2.48-2.26-6.12-7.57,1.43,1.65
			c-1.29-1.58-2.85-3.01-4.2-4.56c-2.74-3.12-5.18-6.43-7.7-9.73c-4.69-6.13,4.6,8.01,0.78,1.27c-1.37-2.42-2.79-4.79-4.05-7.27
			c-1.85-3.62-3.09-7.61-4.99-11.17c3.91,7.33,1.92,5.44,1.18,2.88c-0.56-1.93-1.2-3.82-1.72-5.77c-1.04-3.86-1.89-7.77-2.56-11.7
			c-0.51-3.05,0.45-7.63-0.12,0.55c0.18-2.55-0.48-5.42-0.6-8c-0.43-9.48,0.11-18.99,1.41-28.39c0.27-1.97,2.1-8.38-0.08-0.25
			c0.53-1.98,0.81-4.12,1.25-6.13c1.05-4.8,2.28-9.56,3.67-14.28c5.7-19.37,14.01-37.85,23.77-55.49c-1.29,2.31-2.58,4.61-3.87,6.92
			c7.72-13.75,16.35-26.94,25.76-39.6l-64.79-26.38c0.33,1.81,0.4,4.37,1.01,6.09c-0.47-1.34-0.58-6.82-0.28-2.36
			c0.25,3.74,0.43,7.47,0.38,11.23c-0.04,3.41-0.23,6.8-0.56,10.19c-0.17,1.7-0.42,3.38-0.59,5.07c-0.73,7.06,1.58-6.78,0.03,0.14
			c-3.18,14.21-8.07,27.62-15.04,40.42c1.29-2.31,2.58-4.61,3.87-6.92c-8.89,15.83-20.29,29.83-33.95,41.79
			c2.01-1.72,4.02-3.43,6.03-5.14c-12.76,10.87-26.92,19.9-42.11,26.99c-3.05,1.42-6.76,3.79-10.04,4.35c7.48-1.3,1.44-0.56-0.7,0.2
			c-1.38,0.5-2.76,0.97-4.15,1.44c-8.34,2.8-16.86,5.11-25.46,6.96c-7.9,1.7-15.87,3.02-23.9,3.97c-1.45,0.17-2.91,0.32-4.37,0.48
			c-7.68,0.87,7.49-0.39-0.26,0.05c-4.42,0.25-8.83,0.51-13.25,0.59c-8.13,0.17-16.26-0.03-24.37-0.62
			c-3.69-0.27-7.36-0.62-11.03-1.04c-2.2-0.26-4.4-0.58-6.59-0.86c-7.04-0.89,8.01,1.5-0.79-0.15c-7.6-1.42-15.12-3.2-22.53-5.39
			c-3.87-1.14-7.7-2.39-11.5-3.74c-1.68-0.6-3.42-1.51-5.15-1.91c5.69,2.32,6.69,2.71,3,1.17c-1.35-0.57-2.69-1.16-4.02-1.76
			c-7-3.16-13.82-6.72-20.39-10.7c-1.55-0.94-3.05-2.09-4.65-2.92c10.29,5.33,4.1,2.75,1.42,0.83c-3.35-2.42-6.61-4.96-9.77-7.62
			c-2.64-2.22-5.15-4.58-7.7-6.91c-5.87-5.34,6.86,7.42,1.52,1.55c-1.4-1.53-2.82-3.03-4.2-4.59c-5.04-5.73-9.41-11.88-13.84-18.06
			c7.51,10.48-0.55-1.31-2.1-4.17c-1.64-3.02-3.18-6.09-4.62-9.21c-1.42-3.09-2.44-6.45-3.98-9.47c4.47,8.78,1.35,3.54,0.49,0.84
			c-0.83-2.59-1.59-5.22-2.27-7.86c-1.02-3.95-1.88-7.95-2.55-11.98c-0.28-1.68-0.49-3.36-0.74-5.05c-0.32-2.48-0.19-1.34,0.4,3.4
			c-0.12-1.35-0.22-2.71-0.3-4.07c-0.5-8.61-0.13-17.25,1.09-25.78c0.05-0.35,0.1-0.7,0.15-1.04c0.54-3.34,0.44-2.77-0.31,1.72
			c-0.64-1.43,1.48-6.61,1.88-8.16c1.07-4.04,2.32-8.04,3.72-11.98c3.07-8.58,6.95-16.82,11.35-24.79
			c-1.29,2.31-2.58,4.61-3.87,6.92c13.38-23.6,31.47-43.97,52.01-61.58c-2.01,1.71-4.02,3.42-6.03,5.14
			c7.06-5.96,14.42-11.53,22.35-16.28c4.16-2.5,8.46-4.55,12.8-6.7c5.59-2.77-5.82,1.55,0.63-0.25c2.07-0.58,4.09-1.29,6.18-1.8
			c2.07-0.5,4.13-0.84,6.23-1.24c6.93-1.32-7.17-0.2-0.16,0.02c1.75,0.05,4.53,0.52,6.24,0.28c-5.57-1.05-6.52-1.2-2.82-0.45
			c0.68,0.17,1.37,0.34,2.05,0.52c7.25,1.22-7.19-3.84-1.57-0.82c6.4,3.43-7.55-5.95-2.4-1.51c-3.45-2.71-6.12-6.04-8.01-10
			c1.19,2.44,1.1,2.09-0.28-1.05c-1.21-2.38-1.83-4.92-1.83-7.62c1.1-4.86,1.06-5.76-0.1-2.72c1.29-2.31,2.58-4.61,3.87-6.91
			c-1.28,2.18-2.75,3.92-4.59,5.65c2.01-1.71,4.02-3.43,6.03-5.14c-5.18,4.26-11.26,7.32-17.34,10.08c-1.53,0.7-3.44,1.22-4.86,2.1
			c6.7-4.16,3.76-1.41,0.91-0.42c-3.32,1.17-6.68,2.2-10.07,3.11c-6.21,1.67-12.54,2.92-18.92,3.76c-1.44,0.19-2.92,0.28-4.36,0.51
			c-4.31,0.7,8.73-0.48,2.37-0.2c-3.81,0.17-7.62,0.33-11.44,0.3c-6.18-0.06-12.36-0.48-18.49-1.23c-1.75-0.21-3.51-0.55-5.26-0.73
			c10.96,1.11,4.7,0.78,2.05,0.25c-3.48-0.69-6.95-1.48-10.38-2.38c-6.35-1.66-12.39-3.98-18.61-6.01c12.45,4.07,2.4,0.94-0.74-0.5
			c-3.77-1.73-7.49-3.59-11.13-5.59c-2.6-1.42-5.16-2.92-7.69-4.47c-2.67-1.65-8.02-5.89,2.73,1.94c-1.19-0.87-2.44-1.68-3.64-2.54
			c-5.53-3.96-10.82-8.26-15.8-12.88c-1.3-1.2-2.54-2.48-3.84-3.68c9.75,9.03,4.59,4.63,2.46,2.31c-2.01-2.17-3.94-4.4-5.81-6.69
			c-2.06-2.52-4.03-5.1-5.93-7.76c-0.86-1.2-1.64-2.47-2.52-3.65c7.65,10.23,2.13,3.25,0.51,0.48c-3.46-5.97-6.53-12.16-9.17-18.54
			c-0.48-1.14-0.9-2.33-1.4-3.47c4.35,9.97,1.48,3.97,0.66,1.53c-1.2-3.55-2.27-7.14-3.21-10.76c-1.75-6.67-2.93-13.41-4.02-20.2
			c-1.1-6.84,0.64,7.93,0.09,1.02c-0.14-1.87-0.33-3.74-0.44-5.62c-0.24-3.76-0.36-7.52-0.37-11.28c-0.02-8.18,0.5-16.36,1.54-24.47
			c0.16-1.25,0.33-2.5,0.52-3.74c0.18-1.25,0.38-2.49,0.59-3.74c-0.87,4.81-1,5.6-0.38,2.34c0.8-3.71,1.58-7.4,2.54-11.07
			c4.14-15.91,10.32-31.18,18.31-45.54c-1.29,2.31-2.58,4.61-3.87,6.92c10.43-18.32,23.73-34.54,39.77-48.21l-6.03,5.14
			c8.53-7.11,17.6-13.5,27.23-19.03c4.52-2.59,9.15-5.01,13.87-7.23c2.36-1.11,4.74-2.18,7.14-3.2c1.17-0.5,2.5-0.91,3.62-1.49
			c-2.5,1.32-5.55,2.08-1.1,0.47c10.15-3.69,20.59-6.59,31.19-8.67c5.15-1.02,10.34-1.84,15.56-2.48c1.3-0.16,2.61-0.28,3.91-0.44
			c5.46-0.67-2.03,0.16-2.61,0.18c3.28-0.1,6.56-0.43,9.84-0.53c10.85-0.35,21.72,0.12,32.5,1.4c2.28,0.27,4.56,0.68,6.84,0.91
			c-10.78-1.1-2.16-0.35,0.68,0.22c5.8,1.16,11.55,2.55,17.24,4.19c5.05,1.46,10.01,3.16,14.96,4.95c2.42,0.88,5.87,3-3.59-1.49
			c1.18,0.56,2.44,1.01,3.63,1.53c2.77,1.19,5.5,2.47,8.18,3.85c2.44,1.26,4.84,2.59,7.18,4c1.19,0.71,2.35,1.46,3.53,2.19
			c4.22,2.61-7.47-5.3-3.53-2.28c4.66,3.55,8.95,7.89,13.68,11.28c-7.38-5.29-5.29-5.53-3.02-3.02c1.37,1.52,2.74,3.02,4.05,4.6
			c0.86,1.04,1.7,2.09,2.51,3.16c0.94,1.23,4.39,6.41,0.77,0.78c-3.37-5.24-0.85-1.29-0.06,0.06c0.8,1.4,1.58,2.82,2.29,4.27
			c0.57,1.14,1.05,2.33,1.6,3.48c2.92,6.06-2.03-7.99-0.66-1.47c0.26,1.21,0.66,2.4,0.93,3.62c0.28,1.27,1.02,6.88,0.41,1.25
			c-0.57-5.25-0.17-0.96-0.14,0.38c0.04,1.55,0.01,3.11-0.11,4.65c-0.02,0.26-0.63,3.28-0.42,3.74c0.86-4.47,1-5.54,0.44-3.23
			c-0.22,0.95-0.49,1.89-0.79,2.82c-0.96,3.04-2.25,5.93-3.72,8.75c1.29-2.31,2.59-4.61,3.88-6.92
			c-3.41,6.02-7.72,11.15-12.89,15.74l6.03-5.14c-4.15,3.48-8.57,6.52-13.32,9.1c-2.57,1.4-5.2,2.57-7.85,3.8
			c-3.97,1.85,0.62-1.5,2.06-0.77c-0.66-0.33-3.94,1.34-4.63,1.55c-5.24,1.64-10.6,2.88-16.02,3.78c-2.59,0.43-5.19,0.72-7.79,1.08
			c-5.42,0.74,2.03-0.19,2.63-0.18c-1.93-0.04-3.96,0.24-5.89,0.29c-5.57,0.17-11.15,0.03-16.7-0.41c-2.62-0.21-5.23-0.48-7.83-0.82
			c-1.43-0.19-5.96-0.96-0.35,0.02c6.3,1.11-0.98-0.24-2.26-0.5c-5.46-1.13-10.85-2.55-16.17-4.25c-2.5-0.8-4.96-1.69-7.43-2.58
			c-5.85-2.11,3.68,1.36,3.62,1.47c-0.04,0.09-5.16-2.27-5.39-2.38c-4.75-2.2-9.38-4.64-13.88-7.32c-1.12-0.67-2.22-1.37-3.34-2.05
			c-6.33-3.79,7.18,5.06,3,2.01c-2.38-1.73-4.77-3.42-7.08-5.24c-2.3-1.82-4.55-3.71-6.73-5.66c-1.15-1.03-8.31-8.01-2.67-2.38
			c5.6,5.61-1.69-2.02-2.94-3.49c-2.12-2.49-4.16-5.05-6.09-7.69c-0.8-1.09-1.56-2.19-2.33-3.29c-1.81-2.56-1.33-4.57,2.04,3.17
			c-1.04-2.4-2.78-4.65-4.02-6.96c-1.27-2.36-2.47-4.76-3.59-7.19c-0.56-1.22-1.11-2.44-1.64-3.68c-0.53-1.24-1.04-2.48-1.53-3.72
			c0.6,1.6,1.19,3.2,1.79,4.81c-1.53-5.5-3.54-10.82-4.82-16.41c-0.6-2.62-1.13-5.25-1.58-7.9c-0.28-1.65-0.51-3.32-0.77-4.98
			c-0.92-5.83,0.41,5.55,0.18,1.47c-0.35-6.38-0.69-12.7-0.39-19.1c0.16-3.34,0.42-6.68,0.82-10c0.16-1.33,0.36-2.65,0.53-3.98
			c0.85-6.98-1.56,6.89-0.01,0.03c2.95-13.13,7.38-25.56,13.93-37.34c-1.29,2.31-2.58,4.61-3.88,6.92
			c6.99-12.09,15.81-22.7,26.36-31.83c-2.01,1.71-4.02,3.43-6.03,5.14c6.16-5.18,12.72-9.82,19.7-13.82
			c3.76-2.15,7.63-4.11,11.59-5.88c1.65-0.74,3.58-1.25,5.13-2.17c-6.46,3.86-3.33,1.29-0.54,0.36c7.61-2.56,15.45-4.43,23.4-5.56
			c1.78-0.25,3.76-0.23,5.5-0.66c-8.87,2.21-3.34,0.27-0.21,0.18c3.99-0.13,7.98-0.07,11.96,0.2c1.83,0.13,3.67,0.29,5.49,0.5
			c1.22,0.14,2.43,0.3,3.65,0.48c-1.8-0.29-3.61-0.59-5.4-0.89c4.01,0.13,8.73,1.85,12.54,3c1.78,0.53,3.51,1.14,5.27,1.73
			c6.4,2.17-7.86-3.68-1.25-0.49c5,2.41,9.93,4.79,14.74,7.59c2.05,1.19,4.04,2.48,6.08,3.7c7.42,4.44-7.85-5.53-0.76-0.56
			c4.56,3.19,9.09,6.4,13.57,9.7c3.47,2.55,6.78,5.53,10.35,7.91c-4.88-5.26-6.01-6.32-3.37-3.19c-2-3.95-3.99-7.89-5.99-11.83
			c-0.77-3.21-0.64-6.39,0.37-9.54c-0.35,1.06-0.82,2.06-1.43,3c1.29-2.31,2.58-4.62,3.88-6.92c-1.06,1.47-2.1,2.59-3.45,3.8
			c2.01-1.72,4.02-3.43,6.03-5.14c-0.92,0.74-1.91,1.4-2.95,1.96c4.8-1.59,5.62-1.93,2.47-0.99c-3.4,0.59-2.34,0.51,3.19-0.24
			c5.46,1.12,6.38,1.21,2.74,0.28c5.72,2.49,6.78,2.87,3.17,1.14c-1.28-0.67-2.54-1.4-3.77-2.18c5.62,3.82,6.7,4.5,3.27,2.05
			c-1.17-0.87-2.33-1.74-3.49-2.63c-7.67-5.84-16.98-10.57-26.84-10.92c-7.5-0.27-17.32,2.27-22.04,8.76
			c-11.19,15.41-2.28,36.96,11.59,47.52c14.89,11.33,32.3,22.78,51.76,23.19c17.85,0.38,36.51-7.76,43.47-25.29
			c8.59-21.66-5.27-44.37-21.33-58.2c-11.25-9.69-24.19-17.94-36.57-26.05c-41.38-27.12-93.3-37.24-140.77-20.59
			c-44.44,15.58-80.54,47.99-95.47,93.48c-15.3,46.64-8.75,101.22,17.21,142.91c27.78,44.62,73.2,77.75,124.93,88.8
			c46.97,10.04,105.92,6.6,140.38-31.36c17.86-19.69,23.13-48.93,15.15-74.03c-8.85-27.88-28.01-52.06-51.59-69.16
			c-40.48-29.37-92.29-43.49-142.14-41.93c-45.54,1.42-91.64,13.97-129.05,40.67c-36.68,26.17-64.01,63.44-77.29,106.62
			c-12.92,42.02-13.96,89.48,0.86,131.18c8.23,23.14,19.31,44.78,35.16,63.73c16.01,19.12,35.14,34.7,56.44,47.53
			c38.93,23.45,85.19,33.12,130.21,28.56c20.7-2.1,41.08-8.48,59.54-18.08c14.56-7.57,26.36-18.96,28.11-36.04
			c2.02-19.71-10.95-39.62-26.37-50.82c-29.41-21.37-64.76-21.3-97.04-7.02c-22.52,9.96-42.08,27.4-59.13,44.85
			c-33.81,34.61-59.01,81.13-58.85,130.49c0.17,54.91,27.7,104.93,66.91,141.91c39.22,36.99,93.02,56.35,145.97,61.56
			c96.92,9.55,212.68-32.13,243.12-133.42c7.4-24.63,8.57-49.86,3.92-75.1c-2.65-14.37-16.41-26.71-29.17-31.83
			c-11.04-4.42-27.52-5.43-35.62,5.46c-34.77,46.76-64.36,105.14-62.54,164.9c1.05,34.41,12.33,66.73,32.99,94.27
			c19.61,26.13,48.01,46.66,77.71,59.84c112.85,50.08,252.3,23.18,332.06-73.15c41.4-49.99,65.06-115.1,64.42-180.07
			c-0.18-18.2-13.84-33.78-30.13-40.3c-6.65-2.66-14.94-4.64-22.08-2.6c-8.61,2.46-13.16,7.13-17.41,14.97
			c-23.55,43.51-31.1,97.99-16.61,145.69c14.62,48.15,50.18,91.63,96.9,111.83c25.68,11.1,51.46,15.79,79.35,13.13
			c24.23-2.31,48.17-11.88,68.7-24.74c39.74-24.91,69.92-62.42,89.51-104.68c21.78-47,27.61-100.08,19.01-150.94
			c-2.42-14.33-16.59-26.79-29.16-31.83c-11.58-4.64-26.84-5.08-35.62,5.46c-31.8,38.12-13.34,93.38,19.32,124.04
			c36.88,34.63,95.76,36.59,138.19,11.49c38.27-22.64,61.29-64.2,75.5-104.97c15.63-44.88,22.55-94.17,17.72-141.55
			c-3.18-31.17-12.54-63.26-39.1-82.81c-26.56-19.55-65.1-26.85-90.22-1.11c-10.35,10.6-4.06,30.5,4.22,40.11
			c9.85,11.45,26.95,21.94,42.7,17.63c71.59-19.55,127.37-82.09,142.59-154.36c8.56-40.64,5.65-80.31-9.43-119.06
			c-16.59-42.62-51.57-82.27-94.21-99.96c-48.82-20.26-109.07-16.19-145.57,25.77c-9.78,11.24-4.49,29.99,4.22,40.11
			c4.56,5.29,10,9.76,16.07,13.21c8.61,4.88,17.14,5.24,26.63,4.42c39.41-3.38,77.94-28.24,97.85-62.31
			c11.52-19.71,18.31-41.06,18.42-64.1c0.1-22.89-5.52-45.8-15.62-66.3c-21.52-43.68-64.68-75.31-112.49-83.88
			c-43.68-7.83-88.42-2.33-130.36,11.63c-43.16,14.36-89.25,39.17-103.13,85.95c-3.52,11.84,0.57,23.81,8.09,33.19
			c7.38,9.21,21.62,19.35,34.2,18.34c31.73-2.55,62.23-11.88,88.06-31.05c25.21-18.7,45.11-42.82,57.09-71.98
			c24.28-59.11,6.97-131.44-34.63-178.46c-25.29-28.59-57.62-47.97-93.95-59.3c-33.87-10.55-70.38-14.08-105.7-15.3
			c-71.33-2.46-143.69,7.48-211.4,30.14c-59.01,19.75-114.86,48.34-158.63,93.45c-20.13,20.74-36.92,45.76-47.08,72.9
			c-10.48,27.99-16.16,63.45-6.17,92.31c8.79,25.4,27.92,46.82,54.52,54.01c19.06,5.16,41.75,2.72,55.26-13.11
			c13.64-15.97,10.99-39.26,6.1-58.05c-3.94-15.16-10.02-30.23-18.18-43.62c-34.62-56.81-98.11-98.64-166.16-97.29
			c-61.58,1.22-122.26,33.94-154.73,86.88c-36.14,58.94-36.46,130.49-9.71,193.24c6.75,15.84,15.88,30.6,25.95,44.52
			c5.55,7.67,16.12,14.37,25.17,16.86c7.4,2.03,18.27,2.44,25.03-2.14C1262.77,900.83,1258.01,880.88,1247.89,866.89"
                />
                <path
                    class="st0"
                    d="M1903,961.12c2.1,27.48-11.63,50.94-30.67,52.4c-19.04,1.45-36.17-19.64-38.27-47.13
			c-2.1-27.48,11.63-50.94,30.67-52.4C1883.77,912.53,1900.9,933.64,1903,961.12"
                />
                <path
                    class="st0"
                    d="M1623.22,982.5c2.1,27.48-11.63,50.94-30.67,52.4s-36.17-19.64-38.27-47.13
			c-2.1-27.48,11.63-50.94,30.67-52.4C1603.98,933.92,1621.12,955.01,1623.22,982.5"
                />
                <path
                    class="st0"
                    d="M1436.43,1027.99c-1.12,49.74,31.79,95.24,72.42,121.02c22.15,14.05,46.31,23.55,71.5,30.58
			c25.1,7.01,50.96,11.52,76.88,14.05c52.29,5.1,105.43,2.53,156.4-10.65c45.55-11.78,89.81-33.79,122.8-67.87
			c15.7-16.22,29.24-34.87,39.01-55.25c10.25-21.4,16.3-44.04,17.91-67.7c0.36-5.34,0.43-10.7,0.23-16.05
			c-0.29-8.09-7.55-16.72-15.73-17.75c-8.06-1.02-14.41,4.17-14.11,12.5c0.14,4.05,0.16,8.11-0.02,12.16
			c-0.03,0.87-0.08,1.73-0.13,2.59c-0.03,0.56-0.11,1.16-0.11,1.72c0,0.12,0.28-3.18,0.11-1.59c-0.21,2.01-0.38,4.01-0.63,6.01
			c-1,7.99-2.63,15.9-4.81,23.65c-1.05,3.74-2.24,7.45-3.55,11.12c-0.19,0.54-0.41,1.08-0.59,1.63c-0.03,0.08,1.07-2.78,0.5-1.35
			c-0.37,0.94-0.73,1.88-1.11,2.82c-0.76,1.88-1.55,3.74-2.36,5.59c-3.23,7.31-6.94,14.39-11.06,21.23
			c-4.75,7.88-10.07,15.4-15.84,22.56c0.75-0.93,1.5-1.86,2.26-2.8c-15.11,18.59-33.25,34.26-53.55,46.95
			c1.02-0.63,2.03-1.27,3.05-1.9c-13.68,8.48-28.15,15.62-43.15,21.47c-7.52,2.94-15.17,5.56-22.89,7.88
			c-3.95,1.19-7.93,2.31-11.92,3.34c-0.93,0.24-1.85,0.48-2.77,0.71c-0.5,0.13-3.5,0.87-1.49,0.37c2.01-0.49-1,0.23-1.5,0.34
			c-1.09,0.25-2.17,0.5-3.26,0.74c-33.42,7.37-67.86,10.01-102.04,9.21c-2.16-0.05-4.32-0.12-6.48-0.2
			c-0.53-0.02-3.86-0.17-1.9-0.07c1.91,0.1,0.06,0-0.28-0.02c-0.9-0.05-1.8-0.09-2.7-0.14c-4.49-0.25-8.98-0.56-13.47-0.94
			c-9.15-0.77-18.28-1.8-27.37-3.1c-17.43-2.48-34.77-5.87-51.79-10.4c-2.02-0.53-4.02-1.09-6.03-1.66
			c-0.93-0.27-1.85-0.54-2.77-0.8c-0.46-0.14-0.92-0.28-1.38-0.41c-1.95-0.57,2.98,0.93,1.05,0.32c-3.97-1.27-7.93-2.54-11.85-3.94
			c-7.66-2.74-15.2-5.83-22.55-9.32c-3.58-1.71-7.13-3.51-10.61-5.42c-0.84-0.46-1.66-0.93-2.5-1.4c-1.56-0.87,1.75,0.99,1.6,0.92
			c-0.56-0.27-1.11-0.65-1.64-0.97c-1.77-1.06-3.52-2.15-5.25-3.27c-6.67-4.31-13.07-9.06-19.04-14.3
			c-0.33-0.29-0.65-0.59-0.99-0.88c-1.28-1.11,1.69,1.64,1.67,1.53c-0.07-0.45-1.58-1.48-1.94-1.82c-1.49-1.43-2.96-2.89-4.39-4.39
			c-2.56-2.68-5.02-5.45-7.36-8.31c-1.12-1.37-2.22-2.78-3.29-4.19c-0.39-0.51-1.32-2.3-1.85-2.5c-0.12-0.05,2.27,3.26,1.32,1.84
			c-0.33-0.49-0.67-0.97-1-1.46c-2.06-3.05-4-6.19-5.8-9.4c-1.86-3.33-3.54-6.75-5.11-10.22c-1.18-2.6,1.33,3.31,0.28,0.66
			c-0.32-0.81-0.65-1.63-0.96-2.44c-0.67-1.77-1.31-3.55-1.89-5.35c-1.17-3.59-2.16-7.25-2.94-10.94c-0.18-0.86-0.34-1.71-0.51-2.56
			c-0.33-1.65,0.39,1.94,0.32,1.78c-0.15-0.36-0.14-0.91-0.2-1.29c-0.28-1.87-0.51-3.74-0.68-5.63c-0.35-3.78-0.45-7.59-0.37-11.38
			c0.09-3.92-2.44-8.65-4.94-11.46c-2.58-2.9-6.83-5.78-10.79-6.29c-3.55-0.45-7.61-0.11-10.31,2.57
			C1437.43,1020.84,1436.52,1024.1,1436.43,1027.99"
                />
            </g>
        </g>
    </svg>
</template>

<script lang="ts" src="./AutoConfig.ts"></script>

<template>
    <div>
        <div v-if="!isCompatible" class="notification is-warning">
            <h2>{{ $t('config.usbApiWarning.title') }}</h2>
            <div>
                <p>{{ $t('config.usbApiWarning.message') }}</p>
                <ul>
                    <li>
                        <a href="https://www.google.com/intl/fr/chrome/" target="_blank"
                            >Google Chrome</a
                        >
                    </li>
                    <li>
                        <a href="https://www.microsoft.com/fr-fr/edge/download" target="_blank"
                            >Microsoft Edge</a
                        >
                    </li>
                </ul>
            </div>
            <p>{{ $t('config.thanks') }}</p>
            <h5>{{ $t('config.teamThanks') }}</h5>
        </div>
        <div v-else-if="device == null" class="box">
            <button class="button is-small" type="button" @click="addDevice">
                {{ $t('config.addDevice') }}
            </button>
        </div>
        <div v-else-if="device" class="box">
            <article class="media">
                <div class="media-left">
                    <h2 v-if="device.deviceName" class="subtitle is-4">
                        {{ device.manufacturer }} ({{ device.deviceName }})
                    </h2>
                    <h1 class="title">{{ device.modelName }}</h1>
                    <h2 class="subtitle is-5">{{ device.serial }}</h2>
                    <p v-if="device?.deviceName">
                        {{ $t('config.systemVersion') }}: {{ device.systemVersion }}
                    </p>
                    <p v-if="device?.wifi">
                        Wifi:
                        <span v-if="device?.wifi.enabled">
                            {{ $t('config.wifi.active.title') }}
                            <span v-if="device?.wifi.connected">{{
                                $t('config.wifi.active.connected')
                            }}</span>
                            <span v-else>{{ $t('config.wifi.active.disconnected') }}</span>
                        </span>
                        <span v-else>{{ $t('config.wifi.disabled') }}</span>
                    </p>
                    <p v-if="device?.battery">
                        <span v-if="!device?.battery.present">{{
                            $t('config.battery.notDetected')
                        }}</span>
                        <span v-else>{{
                            $t('config.battery.chargedAt', { percent: device?.battery.percent })
                        }}</span>
                    </p>
                    <p v-if="device?.currentDate">
                        Date courante: {{ device.currentDate.toLocaleDateString() }}
                    </p>
                </div>
                <div v-if="device && deviceLoaded" class="media-content ml-5">
                    <div v-if="error">
                        <div v-if="error.endsWith('Unable to claim interface.')">
                            <h3>
                                <font-awesome-icon :icon="warning()" class="red" />
                                ADB semble être en cours d'utilisation.
                            </h3>
                            <p>
                                Merci de fermer le programme ADB afin de pouvoir utiliser cet outil.
                            </p>
                            <pre>adb kill-server</pre>
                        </div>
                        <div v-else>
                            <h3>
                                <font-awesome-icon :icon="warning()" class="red" />
                                Une erreur inconnue est apparue.
                            </h3>
                            <p>{{ error }}</p>
                        </div>
                    </div>
                    <pico-g2 v-else-if="device.is(DeviceType.PicoG2)" :serial="device.serial" />
                    <pico-g3 v-else-if="device.is(DeviceType.PicoG3)" :serial="device.serial" />
                    <phone v-else-if="device.is(DeviceType.Phone)" :serial="device.serial" />
                </div>
                <div v-else class="media-content ml-5">
                    <h1>Chargement du périphérique...</h1>
                </div>
            </article>
        </div>
    </div>
</template>

<style lang="scss" scoped src="./AutoConfig.scss"></style>

import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, vModelCheckbox as _vModelCheckbox, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { key: 1 }
const _hoisted_6 = { class: "field mb-0" }
const _hoisted_7 = ["disabled"]
const _hoisted_8 = {
  class: "label mt-4",
  for: "override"
}
const _hoisted_9 = ["disabled"]
const _hoisted_10 = {
  key: 2,
  class: "box mt-4 is-bordered"
}
const _hoisted_11 = { class: "subtitle" }
const _hoisted_12 = { class: "content" }
const _hoisted_13 = {
  key: 0,
  class: "progress is-large is-primary"
}
const _hoisted_14 = ["value"]
const _hoisted_15 = { key: 1 }
const _hoisted_16 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_ctx.error != null)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("h2", null, [
          _createVNode(_component_font_awesome_icon, {
            icon: _ctx.warning(),
            class: "red"
          }, null, 8, ["icon"]),
          _createTextVNode(" " + _toDisplayString(_ctx.error), 1)
        ]),
        _createElementVNode("p", null, _toDisplayString(_ctx.$t('setup.contactSupport')), 1)
      ]))
    : (_ctx.device != null && _ctx.deviceLoaded)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (!_ctx.deviceReady)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                (_ctx.configuration)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                      _createVNode(_component_font_awesome_icon, {
                        class: _normalizeClass({ red: !_ctx.configApplied, green: _ctx.configApplied }),
                        icon: _ctx.check()
                      }, null, 8, ["class", "icon"]),
                      _createTextVNode(" Configuration : "),
                      _createElementVNode("b", null, _toDisplayString(_ctx.configuration.name), 1)
                    ]))
                  : (_openBlock(), _createElementBlock("div", _hoisted_5, [
                      _createVNode(_component_font_awesome_icon, {
                        class: "red",
                        icon: _ctx.check()
                      }, null, 8, ["icon"]),
                      _createTextVNode(" " + _toDisplayString(_ctx.$t('setup.configNotFound')), 1)
                    ])),
                _createElementVNode("div", _hoisted_6, [
                  _withDirectives(_createElementVNode("input", {
                    id: "override",
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.override) = $event)),
                    disabled: _ctx.status != '',
                    type: "checkbox",
                    class: "switch is-rounded is-warning"
                  }, null, 8, _hoisted_7), [
                    [_vModelCheckbox, _ctx.override]
                  ]),
                  _createElementVNode("label", _hoisted_8, _toDisplayString(_ctx.$t('setup.erase.base')), 1)
                ]),
                _createElementVNode("button", {
                  disabled: _ctx.status != '',
                  class: "button is-primary mt-4",
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.setupDevice()))
                }, _toDisplayString(_ctx.$t('setup.install')), 9, _hoisted_9),
                (_ctx.status)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                      _createElementVNode("h2", _hoisted_11, _toDisplayString(_ctx.status), 1),
                      _createElementVNode("p", _hoisted_12, _toDisplayString(_ctx.$t('setup.waiting')), 1),
                      (!_ctx.progress)
                        ? (_openBlock(), _createElementBlock("progress", _hoisted_13))
                        : (_openBlock(), _createElementBlock("progress", {
                            key: 1,
                            value: _ctx.progress,
                            class: "progress is-large is-primary",
                            max: "100"
                          }, null, 8, _hoisted_14))
                    ]))
                  : _createCommentVNode("", true)
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_15, [
                _createElementVNode("h2", null, _toDisplayString(_ctx.$t('phone.ready')), 1),
                _createElementVNode("p", null, _toDisplayString(_ctx.$t('headset.thanks')), 1),
                _createElementVNode("div", null, [
                  _createElementVNode("button", {
                    class: "button is-primary mt-4",
                    onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.resetDevice()))
                  }, _toDisplayString(_ctx.$t('headset.reset')), 1),
                  _createElementVNode("button", {
                    disabled: _ctx.status != '',
                    class: "button is-danger is-light is-pulled-right",
                    onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.reboot()))
                  }, _toDisplayString(_ctx.$t('headset.reboot')), 9, _hoisted_16)
                ])
              ]))
        ]))
      : _createCommentVNode("", true)
}
import { Options, Vue } from 'vue-class-component';
import { getEnv } from '@/utility/env';
import CountryFlag from 'vue-country-flag-next';
import Logo from '@/components/Logo.vue';

@Options({ name: 'App', components: { Logo, CountryFlag } })
export default class App extends Vue {
    version = getEnv('APP_VERSION') ?? 'localhost';
    build = getEnv('APP_BUILD');

    selectLang(locale: string) {
        localStorage.setItem('APP_LANGUAGE', locale);
    }
}

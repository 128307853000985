<script lang="ts" src="./Phone.ts"></script>

<template>
    <div v-if="error != null">
        <h2>
            <font-awesome-icon :icon="warning()" class="red" />
            {{ error }}
        </h2>
        <p>{{ $t('setup.contactSupport') }}</p>
    </div>
    <div v-else-if="device != null && deviceLoaded">
        <div v-if="!deviceReady">
            <div v-if="configuration">
                <font-awesome-icon
                    :class="{ red: !configApplied, green: configApplied }"
                    :icon="check()"
                />
                Configuration : <b>{{ configuration.name }}</b>
            </div>
            <div v-else>
                <font-awesome-icon class="red" :icon="check()" />
                {{ $t('setup.configNotFound') }}
            </div>
            <div class="field mb-0">
                <input
                    id="override"
                    v-model="override"
                    :disabled="status != ''"
                    type="checkbox"
                    class="switch is-rounded is-warning"
                />
                <label class="label mt-4" for="override">{{ $t('setup.erase.base') }}</label>
            </div>
            <button :disabled="status != ''" class="button is-primary mt-4" @click="setupDevice()">
                {{ $t('setup.install') }}
            </button>
            <div v-if="status" class="box mt-4 is-bordered">
                <h2 class="subtitle">{{ status }}</h2>
                <p class="content">{{ $t('setup.waiting') }}</p>
                <progress v-if="!progress" class="progress is-large is-primary" />
                <progress v-else :value="progress" class="progress is-large is-primary" max="100" />
            </div>
        </div>
        <div v-else>
            <h2>{{ $t('phone.ready') }}</h2>
            <p>{{ $t('headset.thanks') }}</p>
            <div>
                <button class="button is-primary mt-4" @click="resetDevice()">
                    {{ $t('headset.reset') }}
                </button>
                <button
                    :disabled="status != ''"
                    class="button is-danger is-light is-pulled-right"
                    @click="reboot()"
                >
                    {{ $t('headset.reboot') }}
                </button>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped></style>

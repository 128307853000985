<script lang="ts" src="./App.ts"></script>

<template>
    <div class="navbar-end">
        <div class="navbar-item">
            <div v-for="locale in $i18n.availableLocales" :key="`locale-${locale}`">
                <country-flag
                    class="mr-0 is-clickable"
                    :class="{ selected: $i18n.locale == locale }"
                    size="normal"
                    :country="locale"
                    @click="
                        () => {
                            $i18n.locale = locale;
                            selectLang(locale);
                        }
                    "
                />
            </div>
        </div>
    </div>
    <div class="container pt-6">
        <div class="content">
            <nav aria-label="main navigation" class="navbar" role="navigation">
                <div class="navbar-brand">
                    <a class="navbar-item" href="https://adb.butterfly-tx.com">
                        <Logo class="logo" />
                    </a>
                </div>
                <div class="navbar-menu">
                    <div class="navbar-start">
                        <h1 class="title mt-4 velvet">Butterfly Therapeutics</h1>
                    </div>
                </div>
            </nav>
            <router-view />
        </div>
    </div>
    <div class="version notification is-rounded p-2 m-4">
        Version: {{ version }} / Build: {{ build }}
    </div>
</template>

<style lang="scss" src="./App.scss"></style>

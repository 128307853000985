import App from './App.vue';
import { createApp } from 'vue';

import { createI18n } from 'vue-i18n';
import fr from './locales/fr.json';
import en from './locales/en.json';
import de from './locales/de.json';
import nl from './locales/nl.json';
import it from './locales/it.json';
import se from './locales/se.json';

import router from './router/index';

const lg = localStorage.getItem('APP_LANGUAGE') ?? window.navigator.language;

const i18n = createI18n({
    legacy: false,
    locale: lg,
    fallbackLocale: 'en',
    messages: {
        fr: fr,
        gb: en,
        de: de,
        nl: nl,
        it: it,
        se: se
    }
});

function createVue(): void {
    const app = createApp(App).use(i18n).use(router);
    app.config.errorHandler = (err) => {
        console.error('error', err);
    };
    app.mount('#app');
}

createVue();

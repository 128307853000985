<script lang="ts" src="./PicoG2.ts"></script>

<template>
    <div v-if="error != null">
        <h2>
            <font-awesome-icon :icon="warning()" class="red" />
            {{ error }}
        </h2>
        <p>{{ $t('setup.contactSupport') }}</p>
    </div>
    <div v-else-if="device != null && deviceLoaded">
        <div v-if="!hasRequestVersion()">
            <h2>
                <font-awesome-icon :icon="warning()" class="red" />
                {{ $t('setup.updateRequired', { name: device.name }) }}
            </h2>
            <p v-if="configuration">
                {{ $t('setup.firmwareVersion', { version: configuration.minVersionFirmware }) }}
            </p>
            <p>{{ $t('setup.updateFirmware', { name: device.name }) }}</p>
        </div>
        <div v-else-if="!deviceReady">
            <div v-if="!device.hasServerLicense">
                <font-awesome-icon :icon="check()" class="red" />
                {{ $t('setup.license.server.missing') }}
            </div>
            <div v-else>
                <div>
                    <font-awesome-icon :icon="check()" class="green" />
                    {{ $t('setup.license.server.found') }}
                </div>
                <div v-if="!device.hasDeviceLicense">
                    <font-awesome-icon :icon="check()" class="red" />
                    {{ $t('setup.license.device.missing') }}
                </div>
                <div v-else-if="device.hasLicenseToUpdate">
                    <font-awesome-icon :icon="check()" class="orange" />
                    {{ $t('setup.license.device.update') }}
                </div>
                <div v-else>
                    <font-awesome-icon :icon="check()" class="green" />
                    {{ $t('setup.license.device.found') }}
                </div>
            </div>
            <div v-if="configuration">
                <font-awesome-icon
                    :class="{ orange: !configApplied, green: configApplied }"
                    :icon="check()"
                />
                Configuration : <b>{{ configuration.name }}</b>
            </div>
            <div v-else>
                <font-awesome-icon class="red" :icon="check()" />
                {{ $t('setup.configNotFound') }}
            </div>
            <div v-if="!device.hasTimeUpToDate">
                <font-awesome-icon :icon="check()" class="red" />
                {{ $t('setup.date.needUpdate') }}
            </div>
            <div v-else>
                <font-awesome-icon :icon="check()" class="green" />
                {{ $t('setup.date.upToDate') }}
            </div>
            <div>
                <div class="field mb-0">
                    <input
                        id="override"
                        v-model="override"
                        :disabled="status != ''"
                        type="checkbox"
                        class="switch is-rounded is-warning"
                    />
                    <label class="label mt-4" for="override">{{ $t('setup.erase.base') }}</label>
                </div>
                <div class="field" v-if="override">
                    <input
                        id="overrideLarges"
                        v-model="overrideLargesFiles"
                        :disabled="status != ''"
                        type="checkbox"
                        class="switch is-rounded is-danger"
                    />
                    <label class="label has-text-weight-normal" for="overrideLarges">
                        {{ $t('setup.erase.all') }}
                    </label>
                </div>
                <button :disabled="status != ''" class="button is-primary" @click="setupDevice()">
                    {{ $t('setup.install') }}
                </button>
                <button :disabled="status != ''" class="button is-warning" @click="rebootDevice()">
                    {{ $t('setup.reboot') }}
                </button>
                <div
                    v-if="overrideLargesFiles && status == ''"
                    class="notification is-warning mt-4"
                    v-html="$t('setup.warning')"
                ></div>
            </div>
            <div v-if="status" class="mt-4">
                <h2 class="subtitle">{{ status }}</h2>
                <p class="content">{{ $t('setup.waiting') }}</p>
                <progress v-if="!progress" class="progress is-large is-primary" />
                <progress v-else :value="progress" class="progress is-large is-primary" max="100" />
            </div>
        </div>
        <div v-else>
            <h2>{{ $t('headset.ready') }}</h2>
            <p>{{ $t('headset.thanks') }}</p>
            <button class="button is-primary mt-4" @click="resetDevice()">
                {{ $t('headset.reset') }}
            </button>
            <button class="button is-warning mt-4" @click="rebootDevice()">
                {{ $t('headset.reboot') }}
            </button>
            <div class="box mt-4 columns">
                <div class="column">
                    <h3>{{ $t('headset.license.title') }}</h3>
                    <p>
                        {{ $t('headset.license.owner') }}:
                        <span class="has-text-weight-bold">{{ device.license.owner }}</span
                        ><br />
                        {{ $t('headset.license.usage') }}:
                        <span class="has-text-weight-bold">{{ device.license.usage }}</span
                        ><br />
                        {{ $t('headset.license.expire') }}:
                        <span class="has-text-weight-bold" v-if="device.license.expire">{{
                            device.license.expire.toLocaleDateString()
                        }}</span>
                    </p>
                </div>
                <div class="column">
                    <h3>{{ $t('headset.battery.title') }}</h3>
                    <label class="label">{{
                        $t('headset.battery.charge', { percent: device.battery.percent })
                    }}</label>
                    <progress
                        class="mt-2 progress is-info is-large"
                        v-if="device.battery.percent"
                        max="100"
                        :value="device.battery.percent"
                    />
                </div>
            </div>
        </div>
    </div>
    <div v-else>
        <h1>{{ $t('setup.loading') }}</h1>
    </div>
</template>

<style lang="scss" scoped></style>
